angular.module('fingerink')
    .directive('widgetsMenu', function () {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: 'web/main/send/steps/prepareDocument/widgetsMenu.tpl.html',
            scope: {
                parent: '='
            },
            controllerAs: 'controller',
            controller: 'widgetsMenuCtrl'
        };
    })
    .controller('widgetsMenuCtrl', function ($scope,userService, $translate, session, companyService) {

        var that = this;
        that.sign = $scope.sign;
        that.sessionUser = session.get().user;
        that.parent = $scope.parent;

        that.widgetsMe = [
            {
                name: $translate.instant("Mi nombre"),
                type: "label",
                icon: "ti-text",
                answer: that.sessionUser.name
            },
            {
                name: $translate.instant("Mis apellidos"),
                type: "label",
                icon: "ti-text",
                answer: that.sessionUser.surname
            },
            {
                name: $translate.instant("Mi email"),
                type: "label",
                icon: "ti-email",
                answer: that.sessionUser.email
            },
            {
                name: $translate.instant("Mi empresa"),
                type: "label",
                icon: "ti-briefcase",
                answer: that.sessionUser.company.name
            },
            {
                name: $translate.instant("Dirección de empresa"),
                type: "label",
                icon: "ti-location-pin",
                answer: that.sessionUser.company.address + ', ' + that.sessionUser.company.city + ''
            },
            {
                name: $translate.instant("Mi teléfono"),
                type: "label",
                icon: "ti-mobile",
                answer: that.sessionUser.company.tlf
            },
            {
                name: $translate.instant("Mi ID / Tax Id / VAT number"),
                type: "label",
                icon: "mdi mdi-numeric",
                answer: that.sessionUser.company.nifCif ? that.sessionUser.company.nifCif : that.sessionUser.company.nifCifIva
            },
            {
                name: $translate.instant("Adjuntar imagen"),
                type: "attached",
                icon: "ti-image",
                ratio: "0.3"
            }
        ];

        that.widgetsCheckbox = [
            {
                name: $translate.instant("Checkbox"),
                type: "check",
                icon: "mdi mdi-check"
            },
            {
                name: $translate.instant("Radiobutton"),
                type: "circle",
                icon: "mdi mdi-checkbox-blank-circle"
            },
            {
                name: $translate.instant("Mark with a cross"),
                type: "cross",
                icon: "mdi mdi-close"
            }
        ];

        that.widgets = [
            {
                name: $translate.instant("Firma"),
                type: "sign",
                icon: "ti-marker-alt",
                ratio: "0.3"

            },
            {
                name: $translate.instant("Texto"),
                type: "label",
                icon: "ti-text",
                ratio: "0.1"
            },
            {
                name: $translate.instant("Área de texto"),
                type: "text",
                icon: "ti-align-left",
                ratio: "0.5"
            },
            {
                name: $translate.instant("Fecha"),
                type: "date",
                icon: "fa fa-calendar",
                ratio: "0.16551"
            },
            {
                name: $translate.instant("Nombre"),
                type: "label",
                icon: "ti-text"

            },
            {
                name: $translate.instant("Apellidos"),
                type: "label",
                icon: "ti-text"
            },
            {
                name: $translate.instant("Email"),
                type: "label",
                icon: "ti-email"
            },
            {
                name: $translate.instant("Empresa"),
                type: "label",
                icon: "ti-briefcase"
            },
            {
                name: $translate.instant("Dirección"),
                type: "label",
                icon: "ti-location-pin"

            },
            {
                name: $translate.instant("Teléfono"),
                type: "label",
                icon: "ti-mobile"
            },
            {
                name: $translate.instant("Cantidad"),
                type: "label",
                icon: "mdi mdi-numeric"
            },
            {
                name: $translate.instant("ID / Tax Id / VAT número"),
                type: "label",
                icon: "ti-id-badge"


            },
            {
                name: $translate.instant("Adjuntar imagen"),
                type: "attached",
                icon: "ti-image",
                ratio: "0.3"
            }

        ];

        that.widgetsBasic = [
            {
                name: $translate.instant("Texto"),
                type: "label",
                icon: "ti-text",
                ratio: "0.166"
            },
            {
                name: $translate.instant("Área de texto"),
                type: "text",
                icon: "ti-align-left",
                ratio: "0.5"
            },
            {
                name: $translate.instant("Fecha"),
                type: "date",
                icon: "fa fa-calendar",
                ratio: "0.16551"
            }
        ];



        companyService.companyImageURL().then(function (response) {
            toDataURL(response.data, function (t) {
                that.widgetsMe.unshift({
                    name: $translate.instant("Logo"),
                    type: "sign",
                    icon: "ti-marker-alt",
                    ratio: "0.5",
                    answer: t.replace('data:application/octet-stream;base64,', 'data:image/png;base64,')
                });
                $scope.$apply();
            });
        });


        userService.singImageURL().then(function (response) {
            toDataURL(response.data, function (t) {
                that.widgetsMe.unshift({
                    name: $translate.instant("Mi firma"),
                    type: "sign",
                    icon: "ti-marker-alt",
                    ratio: "0.5",
                    answer: t.replace('data:application/octet-stream;base64,', 'data:image/png;base64,')
                });
                $scope.$apply();
            });

        }, function () {
            that.widgetsMe.unshift({
                name: $translate.instant("Mi firma"),
                type: "sign",
                icon: "ti-marker-alt",
                ratio: "0.5"
            });
        });


        


        function toDataURL(url, callback) {
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
                var reader = new FileReader();
                reader.onloadend = function () {
                    callback(reader.result);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        }


    });
